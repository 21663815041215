import React from "react";
import FAQContents from "./FAQContents";

const FAQ = () => {
	return (
		<div className="wrapper" style={{ marginTop: 0 }}>
			<FAQContents FAQContents={null} />
		</div>
	);
};
export default FAQ;
